@import url('https://fonts.googleapis.com/css?family=Material+Icons|Lato:300,400,700,900&display=swap');

$primary-color: #007acd;
$secondary-color: #ffc107;
$secondary-color-dark: #c99600;
$negative-color: #db3735;
$engie-gradient: linear-gradient(135deg, #00aaff 0.89%, #23d2b5 94.05%);
$engie-primary: #007acd;
$engie-accent: #fbdb3c;
$engie-button-primary: #007acd;
$engie-bg-warning: #db3735;
$engie-bg-success: #008836;

$background: white;

$panel-bg-info: #e8f0fe;
$panel-bg-warn: #fef7e0;
$panel-bg-error: #fce8e6;

$green-java: #6ed2b1;
$red-mandy: #db3735;
$blue-corporate: #009de9;
$yellow-bright-sun: #fbdb3c;
$green-pine: #007873;
$green: #418448;
$red: #db3735;
$grey: #808080;
$success-green: #92d050;
